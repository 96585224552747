
import {
  defineComponent,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { rediredAsPerLoggedInUser } from "@/filters";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const mrbUrlTerms = process.env.VUE_APP_HOME_PAGE_URL_TERMS;
    let countries = ref([
      {
        name: "Afghanistan",
        value: "afghanistan",
      },
      {
        name: "Albania",
        value: "albania",
      },
      {
        name: "Algeria",
        value: "algeria",
      },
      {
        name: "American Samoa",
        value: "american samoa",
      },
      {
        name: "Andorra",
        value: "andorra",
      },
      {
        name: "Angola",
        value: "angola",
      },
      {
        name: "Anguilla",
        value: "anguilla",
      },
      {
        name: "Antarctica",
        value: "antarctica",
      },
      {
        name: "Antigua and Barbuda",
        value: "antigua and barbuda",
      },
      {
        name: "Argentina",
        value: "argentina",
      },
      {
        name: "Armenia",
        value: "armenia",
      },
      {
        name: "Aruba",
        value: "aruba",
      },
      {
        name: "Australia",
        value: "australia",
      },
      {
        name: "Austria",
        value: "austria",
      },
      {
        name: "Azerbaijan",
        value: "azerbaijan",
      },
      {
        name: "Bahamas (the)",
        value: "bahamas (the)",
      },
      {
        name: "Bahrain",
        value: "bahrain",
      },
      {
        name: "Bangladesh",
        value: "bangladesh",
      },
      {
        name: "Barbados",
        value: "barbados",
      },
      {
        name: "Belarus",
        value: "belarus",
      },
      {
        name: "Belgium",
        value: "belgium",
      },
      {
        name: "Belize",
        value: "belize",
      },
      {
        name: "Benin",
        value: "benin",
      },
      {
        name: "Bermuda",
        value: "bermuda",
      },
      {
        name: "Bhutan",
        value: "bhutan",
      },
      {
        name: "Bolivia (Plurinational State of)",
        value: "bolivia (plurinational state of)",
      },
      {
        name: "Bonaire, Sint Eustatius and Saba",
        value: "bonaire, sint eustatius and saba",
      },
      {
        name: "Bosnia and Herzegovina",
        value: "bosnia and herzegovina",
      },
      {
        name: "Botswana",
        value: "botswana",
      },
      {
        name: "Bouvet Island",
        value: "bouvet island",
      },
      {
        name: "Brazil",
        value: "brazil",
      },
      {
        name: "British Indian Ocean Territory (the)",
        value: "british indian ocean territory (the)",
      },
      {
        name: "Brunei Darussalam",
        value: "brunei darussalam",
      },
      {
        name: "Bulgaria",
        value: "bulgaria",
      },
      {
        name: "Burkina Faso",
        value: "burkina faso",
      },
      {
        name: "Burundi",
        value: "burundi",
      },
      {
        name: "Cabo Verde",
        value: "cabo verde",
      },
      {
        name: "Cambodia",
        value: "cambodia",
      },
      {
        name: "Cameroon",
        value: "cameroon",
      },
      {
        name: "Canada",
        value: "canada",
      },
      {
        name: "Cayman Islands (the)",
        value: "cayman islands (the)",
      },
      {
        name: "Central African Republic (the)",
        value: "central african republic (the)",
      },
      {
        name: "Chad",
        value: "chad",
      },
      {
        name: "Chile",
        value: "chile",
      },
      {
        name: "China",
        value: "china",
      },
      {
        name: "Christmas Island",
        value: "christmas island",
      },
      {
        name: "Cocos (Keeling) Islands (the)",
        value: "cocos (keeling) islands (the)",
      },
      {
        name: "Colombia",
        value: "colombia",
      },
      {
        name: "Comoros (the)",
        value: "comoros (the)",
      },
      {
        name: "Congo (the Democratic Republic of the)",
        value: "congo (the democratic republic of the)",
      },
      {
        name: "Congo (the)",
        value: "congo (the)",
      },
      {
        name: "Cook Islands (the)",
        value: "cook islands (the)",
      },
      {
        name: "Costa Rica",
        value: "costa rica",
      },
      {
        name: "Croatia",
        value: "croatia",
      },
      {
        name: "Cuba",
        value: "cuba",
      },
      {
        name: "Curaçao",
        value: "curaçao",
      },
      {
        name: "Cyprus",
        value: "cyprus",
      },
      {
        name: "Czechia",
        value: "czechia",
      },
      {
        name: "Côte d'Ivoire",
        value: "côte d'ivoire",
      },
      {
        name: "Denmark",
        value: "denmark",
      },
      {
        name: "Djibouti",
        value: "djibouti",
      },
      {
        name: "Dominica",
        value: "dominica",
      },
      {
        name: "Dominican Republic (the)",
        value: "dominican republic (the)",
      },
      {
        name: "Ecuador",
        value: "ecuador",
      },
      {
        name: "Egypt",
        value: "egypt",
      },
      {
        name: "El Salvador",
        value: "el salvador",
      },
      {
        name: "Equatorial Guinea",
        value: "equatorial guinea",
      },
      {
        name: "Eritrea",
        value: "eritrea",
      },
      {
        name: "Estonia",
        value: "estonia",
      },
      {
        name: "Eswatini",
        value: "eswatini",
      },
      {
        name: "Ethiopia",
        value: "ethiopia",
      },
      {
        name: "Falkland Islands (the) [Malvinas]",
        value: "falkland islands (the) [malvinas]",
      },
      {
        name: "Faroe Islands (the)",
        value: "faroe islands (the)",
      },
      {
        name: "Fiji",
        value: "fiji",
      },
      {
        name: "Finland",
        value: "finland",
      },
      {
        name: "France",
        value: "france",
      },
      {
        name: "French Guiana",
        value: "french guiana",
      },
      {
        name: "French Polynesia",
        value: "french polynesia",
      },
      {
        name: "French Southern Territories (the)",
        value: "french southern territories (the)",
      },
      {
        name: "Gabon",
        value: "gabon",
      },
      {
        name: "Gambia (the)",
        value: "gambia (the)",
      },
      {
        name: "Georgia",
        value: "georgia",
      },
      {
        name: "Germany",
        value: "germany",
      },
      {
        name: "Ghana",
        value: "ghana",
      },
      {
        name: "Gibraltar",
        value: "gibraltar",
      },
      {
        name: "Greece",
        value: "greece",
      },
      {
        name: "Greenland",
        value: "greenland",
      },
      {
        name: "Grenada",
        value: "grenada",
      },
      {
        name: "Guadeloupe",
        value: "guadeloupe",
      },
      {
        name: "Guam",
        value: "guam",
      },
      {
        name: "Guatemala",
        value: "guatemala",
      },
      {
        name: "Guernsey",
        value: "guernsey",
      },
      {
        name: "Guinea",
        value: "guinea",
      },
      {
        name: "Guinea-Bissau",
        value: "guinea-bissau",
      },
      {
        name: "Guyana",
        value: "guyana",
      },
      {
        name: "Haiti",
        value: "haiti",
      },
      {
        name: "Heard Island and McDonald Islands",
        value: "heard island and mcdonald islands",
      },
      {
        name: "Holy See (the)",
        value: "holy see (the)",
      },
      {
        name: "Honduras",
        value: "honduras",
      },
      {
        name: "Hong Kong",
        value: "hong kong",
      },
      {
        name: "Hungary",
        value: "hungary",
      },
      {
        name: "Iceland",
        value: "iceland",
      },
      {
        name: "India",
        value: "india",
      },
      {
        name: "Indonesia",
        value: "indonesia",
      },
      {
        name: "Iran (Islamic Republic of)",
        value: "iran (islamic republic of)",
      },
      {
        name: "Iraq",
        value: "iraq",
      },
      {
        name: "Ireland",
        value: "ireland",
      },
      {
        name: "Isle of Man",
        value: "isle of man",
      },
      {
        name: "Israel",
        value: "israel",
      },
      {
        name: "Italy",
        value: "italy",
      },
      {
        name: "Jamaica",
        value: "jamaica",
      },
      {
        name: "Japan",
        value: "japan",
      },
      {
        name: "Jersey",
        value: "jersey",
      },
      {
        name: "Jordan",
        value: "jordan",
      },
      {
        name: "Kazakhstan",
        value: "kazakhstan",
      },
      {
        name: "Kenya",
        value: "kenya",
      },
      {
        name: "Kiribati",
        value: "kiribati",
      },
      {
        name: "Korea (the Democratic People's Republic of)",
        value: "korea (the democratic people's republic of)",
      },
      {
        name: "Korea (the Republic of)",
        value: "korea (the republic of)",
      },
      {
        name: "Kuwait",
        value: "kuwait",
      },
      {
        name: "Kyrgyzstan",
        value: "kyrgyzstan",
      },
      {
        name: "Lao People's Democratic Republic (the)",
        value: "lao people's democratic republic (the)",
      },
      {
        name: "Latvia",
        value: "latvia",
      },
      {
        name: "Lebanon",
        value: "lebanon",
      },
      {
        name: "Lesotho",
        value: "lesotho",
      },
      {
        name: "Liberia",
        value: "liberia",
      },
      {
        name: "Libya",
        value: "libya",
      },
      {
        name: "Liechtenstein",
        value: "liechtenstein",
      },
      {
        name: "Lithuania",
        value: "lithuania",
      },
      {
        name: "Luxembourg",
        value: "luxembourg",
      },
      {
        name: "Macao",
        value: "macao",
      },
      {
        name: "Madagascar",
        value: "madagascar",
      },
      {
        name: "Malawi",
        value: "malawi",
      },
      {
        name: "Malaysia",
        value: "malaysia",
      },
      {
        name: "Maldives",
        value: "maldives",
      },
      {
        name: "Mali",
        value: "mali",
      },
      {
        name: "Malta",
        value: "malta",
      },
      {
        name: "Marshall Islands (the)",
        value: "marshall islands (the)",
      },
      {
        name: "Martinique",
        value: "martinique",
      },
      {
        name: "Mauritania",
        value: "mauritania",
      },
      {
        name: "Mauritius",
        value: "mauritius",
      },
      {
        name: "Mayotte",
        value: "mayotte",
      },
      {
        name: "Mexico",
        value: "mexico",
      },
      {
        name: "Micronesia (Federated States of)",
        value: "micronesia (federated states of)",
      },
      {
        name: "Moldova (the Republic of)",
        value: "moldova (the republic of)",
      },
      {
        name: "Monaco",
        value: "monaco",
      },
      {
        name: "Mongolia",
        value: "mongolia",
      },
      {
        name: "Montenegro",
        value: "montenegro",
      },
      {
        name: "Montserrat",
        value: "montserrat",
      },
      {
        name: "Morocco",
        value: "morocco",
      },
      {
        name: "Mozambique",
        value: "mozambique",
      },
      {
        name: "Myanmar",
        value: "myanmar",
      },
      {
        name: "Namibia",
        value: "namibia",
      },
      {
        name: "Nauru",
        value: "nauru",
      },
      {
        name: "Nepal",
        value: "nepal",
      },
      {
        name: "Netherlands (the)",
        value: "netherlands (the)",
      },
      {
        name: "New Caledonia",
        value: "new caledonia",
      },
      {
        name: "New Zealand",
        value: "new zealand",
      },
      {
        name: "Nicaragua",
        value: "nicaragua",
      },
      {
        name: "Niger (the)",
        value: "niger (the)",
      },
      {
        name: "Nigeria",
        value: "nigeria",
      },
      {
        name: "Niue",
        value: "niue",
      },
      {
        name: "Norfolk Island",
        value: "norfolk island",
      },
      {
        name: "Northern Mariana Islands (the)",
        value: "northern mariana islands (the)",
      },
      {
        name: "Norway",
        value: "norway",
      },
      {
        name: "Oman",
        value: "oman",
      },
      {
        name: "Pakistan",
        value: "pakistan",
      },
      {
        name: "Palau",
        value: "palau",
      },
      {
        name: "Palestine, State of",
        value: "palestine, state of",
      },
      {
        name: "Panama",
        value: "panama",
      },
      {
        name: "Papua New Guinea",
        value: "papua new guinea",
      },
      {
        name: "Paraguay",
        value: "paraguay",
      },
      {
        name: "Peru",
        value: "peru",
      },
      {
        name: "Philippines (the)",
        value: "philippines (the)",
      },
      {
        name: "Pitcairn",
        value: "pitcairn",
      },
      {
        name: "Poland",
        value: "poland",
      },
      {
        name: "Portugal",
        value: "portugal",
      },
      {
        name: "Puerto Rico",
        value: "puerto rico",
      },
      {
        name: "Qatar",
        value: "qatar",
      },
      {
        name: "Republic of North Macedonia",
        value: "republic of north macedonia",
      },
      {
        name: "Romania",
        value: "romania",
      },
      {
        name: "Russian Federation (the)",
        value: "russian federation (the)",
      },
      {
        name: "Rwanda",
        value: "rwanda",
      },
      {
        name: "Réunion",
        value: "réunion",
      },
      {
        name: "Saint Barthélemy",
        value: "saint barthélemy",
      },
      {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        value: "saint helena, ascension and tristan da cunha",
      },
      {
        name: "Saint Kitts and Nevis",
        value: "saint kitts and nevis",
      },
      {
        name: "Saint Lucia",
        value: "saint lucia",
      },
      {
        name: "Saint Martin (French part)",
        value: "saint martin (french part)",
      },
      {
        name: "Saint Pierre and Miquelon",
        value: "saint pierre and miquelon",
      },
      {
        name: "Saint Vincent and the Grenadines",
        value: "saint vincent and the grenadines",
      },
      {
        name: "Samoa",
        value: "samoa",
      },
      {
        name: "San Marino",
        value: "san marino",
      },
      {
        name: "Sao Tome and Principe",
        value: "sao tome and principe",
      },
      {
        name: "Saudi Arabia",
        value: "saudi arabia",
      },
      {
        name: "Senegal",
        value: "senegal",
      },
      {
        name: "Serbia",
        value: "serbia",
      },
      {
        name: "Seychelles",
        value: "seychelles",
      },
      {
        name: "Sierra Leone",
        value: "sierra leone",
      },
      {
        name: "Singapore",
        value: "singapore",
      },
      {
        name: "Sint Maarten (Dutch part)",
        value: "sint maarten (dutch part)",
      },
      {
        name: "Slovakia",
        value: "slovakia",
      },
      {
        name: "Slovenia",
        value: "slovenia",
      },
      {
        name: "Solomon Islands",
        value: "solomon islands",
      },
      {
        name: "Somalia",
        value: "somalia",
      },
      {
        name: "South Africa",
        value: "south africa",
      },
      {
        name: "South Georgia and the South Sandwich Islands",
        value: "south georgia and the south sandwich islands",
      },
      {
        name: "South Sudan",
        value: "south sudan",
      },
      {
        name: "Spain",
        value: "spain",
      },
      {
        name: "Sri Lanka",
        value: "sri lanka",
      },
      {
        name: "Sudan (the)",
        value: "sudan (the)",
      },
      {
        name: "Suriname",
        value: "suriname",
      },
      {
        name: "Svalbard and Jan Mayen",
        value: "svalbard and jan mayen",
      },
      {
        name: "Sweden",
        value: "sweden",
      },
      {
        name: "Switzerland",
        value: "switzerland",
      },
      {
        name: "Syrian Arab Republic",
        value: "syrian arab republic",
      },
      {
        name: "Taiwan",
        value: "taiwan",
      },
      {
        name: "Tajikistan",
        value: "tajikistan",
      },
      {
        name: "Tanzania, United Republic of",
        value: "tanzania, united republic of",
      },
      {
        name: "Thailand",
        value: "thailand",
      },
      {
        name: "Timor-Leste",
        value: "timor-leste",
      },
      {
        name: "Togo",
        value: "togo",
      },
      {
        name: "Tokelau",
        value: "tokelau",
      },
      {
        name: "Tonga",
        value: "tonga",
      },
      {
        name: "Trinidad and Tobago",
        value: "trinidad and tobago",
      },
      {
        name: "Tunisia",
        value: "tunisia",
      },
      {
        name: "Turkey",
        value: "turkey",
      },
      {
        name: "Turkmenistan",
        value: "turkmenistan",
      },
      {
        name: "Turks and Caicos Islands (the)",
        value: "turks and caicos islands (the)",
      },
      {
        name: "Tuvalu",
        value: "tuvalu",
      },
      {
        name: "Uganda",
        value: "uganda",
      },
      {
        name: "Ukraine",
        value: "ukraine",
      },
      {
        name: "United Arab Emirates (the)",
        value: "united arab emirates (the)",
      },
      {
        name: "United Kingdom of Great Britain and Northern Ireland (the)",
        value: "united kingdom of great britain and northern ireland (the)",
      },
      {
        name: "United States Minor Outlying Islands (the)",
        value: "united states minor outlying islands (the)",
      },
      {
        name: "United States of America (the)",
        value: "united states of america (the)",
      },
      {
        name: "Uruguay",
        value: "uruguay",
      },
      {
        name: "Uzbekistan",
        value: "uzbekistan",
      },
      {
        name: "Vanuatu",
        value: "vanuatu",
      },
      {
        name: "Venezuela (Bolivarian Republic of)",
        value: "venezuela (bolivarian republic of)",
      },
      {
        name: "Viet Nam",
        value: "viet nam",
      },
      {
        name: "Virgin Islands (British)",
        value: "virgin islands (british)",
      },
      {
        name: "Virgin Islands (U.S.)",
        value: "virgin islands (u.s.)",
      },
      {
        name: "Wallis and Futuna",
        value: "wallis and futuna",
      },
      {
        name: "Western Sahara",
        value: "western sahara",
      },
      {
        name: "Yemen",
        value: "yemen",
      },
      {
        name: "Zambia",
        value: "zambia",
      },
      {
        name: "Zimbabwe",
        value: "zimbabwe",
      },
      {
        name: "Åland Islands",
        value: "åland islands",
      },
    ]);

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string().required().label("Name"),
      last_name: Yup.string().required().label("Surname"),
      email: Yup.string().min(4).required().email().label("Email"),
      company_name: Yup.string().min(4).required().label("Company Name"),
      account_type: Yup.string().required().label("Account Type"),
      country_incorporation: Yup.string().required().label("Country of Incorporation"),
      zip_code: Yup.string().required().label("Zip Code"),
      street_address: Yup.string().required().label("Street Address"),
      city: Yup.string().required().label("City"),
      password: Yup.string().required().label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      toc: Yup.bool().oneOf(
        [true],
        "Please accept terms and conditions"
      ),
    });

    onMounted(async () => {
      //check if current user is authenticated
      if (store.getters.isUserAuthenticated) {
        rediredAsPerLoggedInUser(store, router);
      }

      // await store.dispatch(Actions.GET_COUNTRIES);
      // countries.value = store.getters.getCountries;

      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    const onSubmitRegister = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      //Setting default values
      values.username = values.email;
      values.question = 1;
      values.user_type = 1;
      // Send login request
      await store.dispatch(Actions.REGISTER, values);

      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];

      if (!error) {
        Swal.fire({
          text: "SignUp successfull! Please validate your email before signin",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(async () => {
          //Send login request
          const email_otp_params = { email: values.email };
          await store.dispatch(Actions.SEND_EMAIL_OTP, email_otp_params);

          //Go to email validation page
          router.push({
            name: "email-validation",
            params: { email: values.email },
          });
        });
      } else {
        Swal.fire({
          text: error[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      mrbUrlTerms,
      countries,
    };
  },
});
